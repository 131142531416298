.login-page-container {
    $landing-page-height: 450px;

    min-height:  $landing-page-height;
    height: 100vh;
    width: 100%;
    // background: url("../../images/stock/man_at_desk.jpg") no-repeat center fixed;
    // background-size: cover;
    // -webkit-background-size: cover;
    // -moz-background-size: cover;
    // -o-background-size: cover;
    // background-position-y: 30px;
    // @media only screen and (max-width: 450px) {
    //     background-position-x: -440px;
    // }

    .errorMsg {
        position: absolute;
        font-size: 10px;
        color: rgb(204, 26, 26);
        font-weight: bold;
    }
    .background-image {
        height: 100%;
        min-height: 500px;
        width: 100%;
        object-fit: cover;
        @media only screen and (max-width: 500px) {
            object-position: 60%;
        }
    }

    .landing-content {
        background: rgba( $primary-2, 0.8 );
        border-radius: 20px;
        height: 250px;
        width: 300px;
        position: absolute;
        left: calc(100vw - 90vw);
        top: 225px;
        @media only screen and (max-width: 450px) {
            left: calc(100vw - 90vw);
        }
        .login-form {
            margin-left: 25px;
            margin-top: 10px;
            .form-control {
                width: 80%;
                min-width: 250px;
            }
            .button-container {
                padding-top: 15px;
                padding-right: 10%;
            }
        }
    }
}