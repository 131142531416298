@keyframes wiggle {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(0deg);
        box-shadow: 0 0 3px 2px $primary-2;
    }
    30% {
        transform: rotate(3deg);
        box-shadow: 0 0 3px 2px $primary-2;
    }
    35% {
        transform: rotate(-3deg);
        box-shadow: 0 0 3px 2px $primary-2;
    }
    40% {
        transform: rotate(3deg);
        box-shadow: 0 0 3px 2px $primary-2;
    }
    45% {
        transform: rotate(-3deg);
        box-shadow: 0 0 3px 2px $primary-2;
    }
    50% {
        transform: rotate(0deg);
        box-shadow: 0 0 3px 2px $primary-2;
    }
    100% {
        transform: rotate(0deg);
    }
}

@keyframes wiggle2 {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(0deg);
    }
    30% {
        transform: rotate(3deg);
    }
    35% {
        transform: rotate(-3deg);
    }
    40% {
        transform: rotate(3deg);
    }
    45% {
        transform: rotate(-3deg);
    }
    50% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@keyframes extend-right {
    0% {
        transition: all 0.3s ease;
        background-color: $primary-3;
        box-shadow: 0 0 3px 0px $primary-3;
        width: 0%;
    }

    100% {
        transition: all 0.3s ease;
        background-color: $primary-2;
        box-shadow: 0 0 3px 3px $primary-3;
        width: 80%;
    }
}

@keyframes fade-in-and-fade-out {
    0% {
        transform: translateY(-5px);
    }
    20% {
        transform: translateY(0);
        opacity: 1;
    }
    80% {
        transform: translateY(0px);
        opacity: 1;
    }
    100% {
        transform: translateY(5px);
        opacity: 0;
    }
}

@keyframes fade-in {
    0% {
        transition: all 0.4s ease;
        opacity: 0
    }

    100% {
        transition: all 0.4s ease;
        opacity: 1
    }
}