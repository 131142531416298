.cancel-bid-modal {
    margin-top: 200px;
}

.vendor-stripe-registration-complete-modal {
    margin-top: 100px;
}

.accept-proposal-modal {
    margin-top: 100px;
}

.vendor-dashboard-container, .vendor-projects-container {
    margin-top: 5px;
    background: $primary-gradient-0;
    box-shadow: 0 0 5px 1px $primary-0;
    border-radius: 5px;
    margin-left: 1px;
    padding-top: 2px;



    .search-for-navbar{
        padding: 0px;
        li {
            transition: all 0.4s ease;
            display: inline-block;
            padding: 10px; 
            background: $primary-4;
            box-shadow: 0 0 3px 1px $primary-3;
            border-radius: 10px;
            margin-left: 10px;
            margin-top: 15px;
            &:hover {
                cursor: pointer;
                background: $primary-3;
            }
            &.active {
                box-shadow: 0 0 3px 3px $primary-2;
                background: $primary-3;
                filter: brightness(110%);
                &:hover {
                    cursor: pointer;
                    background: $primary-3;
                    filter: brightness(110%);
                }
            }
        }
    }
    

    .my-projects-search-bar {
        width: calc(100% - 50px);
    }
    .my-projects-search-btn {
        width: 50px;
        margin-top: -5px;
        height: 38px;
    }

    .collapsed {
        transition: all 0.3s ease;
        max-height: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        overflow: hidden;
    }

    .status-circle {
        height: 15px;
        height: 38px;
        width: 4px;
        position: absolute;
        left: 3px;
        top: 2px;
        border-radius: 5px;
    }
    .goto-circle {
        height: 15px;
        width: 35px;
        position: absolute;
        right: 5px;
        top: 2px;
        border-radius: 5px;
        background: $primary-4;
        &:hover {
            cursor: pointer;
            background: $primary-6;
        }
        &.trash-can {
            transition: all 0.3s ease;
            background: $danger-2;
            position: relative;
            color: $primary-0;
            margin-top: 2px;
            margin-right: -5px;
            float: right;
            border-radius: 4px;
            box-shadow: 0 0 3px 1px white;
            &:hover {
                cursor: pointer;
                background: $danger-3;
            }
        }
        &.edit-btn {
            transition: all 0.3s ease;
            position: relative;
            margin-top: 2px;
            margin-right: 5px;
            float: right;
            border-radius: 4px;
            box-shadow: 0 0 3px 1px $primary-2;
            background: $primary-3;
            &:hover {
                cursor: pointer;
                background: $primary-4;
            }
        }
    }
    .project-group-overview {
        padding-left: 20px;
        width: calc(100% - 100px);
    }

    &.left-dashboard {
        //max-width: 600px;
        @media only screen and (max-width: 700px) {
            max-width: 100%;
        }   
    }
    &.right-dashboard {
        max-width: 250px;
        @media only screen and (max-width: 775px) {
            display: none;
        }
    }
    .card {
        box-shadow: 0 0 2px 2px $primary-2;
        font-size: 12px;
    }
    .card-header {
        max-height: 42px;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        padding: 5px 0px 5px 0px;
    }
    .card-body {
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
    }
    .project-list-item-header {
        min-height: 42px;
        font-size: 14px;
        width: calc( 100% - 30px);
        max-height: 100%!important;
    }
    .project-list-feature-locked {
        position: absolute;
        padding-top: 5px;
        padding-left: 25px;
        z-index: 10;
        width: 100%;
        height: 100%;
        text-align: left;
        &:hover {
            filter: brightness(120%);
            cursor: pointer;
        }
        .lock-icon {
            color: $primary-0;
            font-size: 20px;
        }
        .lock-text {
            text-shadow: 1px 1px 0px $primary-0, 1px 0px 1px $primary-0, 0px 1px 1px $primary-0;
            color: $primary-5;
            padding-left: 10px;
            font-size: 14px;
            font-weight: bold;
        }
    }

    .project-page-visit-button {
        transition: all 0.3s ease;
        position: absolute;
        width: 30px;
        right: 0px;
        top: 0px;
        height: 42px;
        padding-top: 10px;
        color: $primary-0;
        font-size: 18px;
        div {
            height: 100%;
            margin-top: -2px;
        }
        &:hover {
            background: rgba($primary-2, 0.8);
            box-shadow: 0px -1px 2px 1px rgba($primary-2, 0.8);
            border-radius: 0 5px 5px 0;
        }
    }
    .project-group-header-name {
        transition: all 0.3s ease;
        width: calc(100% - 10px);
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        margin-left: 10px;
        margin-top: -1px;
        &:hover {
            background: rgba($primary-2, 0.8);
            box-shadow: 0 0 2px 1px $primary-2;
//            filter: brightness(110%);
        }
    }
    .project-client-list-title {
        margin-top: -5px;
        width: calc(100% - 15px);
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        margin-left: 10px;
    }
    .chart-box {
        padding: 0px;
        width: 100%;
        height: 50%;
    }
    .hide-on-compact {
        @media only screen and (min-width: 775px) {
            display: none;
        }
    }
    .collapsed {
        transition: all 0.3s ease;
        max-height: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        overflow: hidden;
    }

    textarea {
        min-height: 300px;
    }
    .public-private-status-icon {
        width: 20px;
        position: absolute;
        left: 15px;
        &.public {
            color: $primary-1;
        }
        &.private {
            color: $primary-1;
            filter: brightness(150%);
        }
    }
    .my-bid-title {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        margin-left: 75px;
        width: calc(100% - 80px);
    }
    .bid-created-date {
        position: absolute;
        margin-left: 15px;
        margin-top: 18px;
    }
}


.vendor-project-detail-container {
    margin-top: 5px;
    background: $primary-gradient-0;
    box-shadow: 0 0 5px 1px $primary-0;
    border-radius: 5px;
    margin-left: 1px;
    padding-top: 2px;

    

    .bookmark-private {
        transition: all 0.2s ease;
        max-height: 3000px;
        overflow: hidden;
        white-space: nowrap;
        &.collapse-me {
            transition: all 0.7s ease;
            max-height: 0px;
            overflow: hidden;
            white-space: nowrap;
        }
    }
    .bookmark-selector {
        ul {
            list-style: none;
        }
        li {
            transition: all 0.4s ease;
            display: inline-block;
            border-radius: 10px;
            padding: 5px;
            margin-bottom: 10px;
            margin-right: 10px;
            box-shadow: 0 0 3px 1px $primary-2;
            background: $primary-5;
        }
        li:hover {
            cursor: grab;
            background: $primary-4;
        }
        .selected {
            background: lighten($primary-3, 5%);
            &:hover {
                background: $primary-4;
            }
        }
    }
    
    .project-task-list-button-alert {
        opacity: 0;
        border: 1px $primary-3 solid;
        height: 55px;
        width: 200px;
        background: $primary-6;
        border-radius: 10px 10px 10px 10px;
        box-shadow: 0 0 3px 1px $primary-2;
        margin: auto;
        animation: fade-in-and-fade-out 8s 1;
        .pointer {
            background: $primary-6;
            
            border-left: 1px $primary-3 solid;
            border-top: 1px $primary-3 solid;
            position: absolute;
            transform: rotate(45deg);
            margin-top: -10px;
            margin-left: 85px;
            width: 20px;
            height: 20px;
        }
        .text-content {
            width: 93%;
            padding-left: 7%;
        }
    }

    .project-task-list-button-alert-2 {
        opacity: 0;
        border: 1px $primary-3 solid;
        height: 55px;
        width: 200px;
        background: $primary-6;
        border-radius: 10px 10px 10px 10px;
        box-shadow: 0 0 3px 1px $primary-2;
        margin: auto;
        position: absolute;
        top: 0;
        margin-bottom: 15px;
        margin-left: calc( 100% - 200px );
        padding-bottom: 70px;
        animation: fade-in-and-fade-out 8s 1;
        z-index: 1000;
        .pointer {
            background: $primary-6;
            border-left: 1px $primary-3 solid;
            border-top: 1px $primary-3 solid;
            position: absolute;
            transform: rotate(-135deg);
            margin-top: 61px;
            margin-left: 160px;
            width: 20px;
            height: 20px;
            z-index: 100;
        }
        .text-content {
            width: 93%;
            padding-left: 7%;
        }
    }

    .proposal-business-link {
        transition: all 0.4s ease;
        div {
            transition: color 0.4s ease;
        }
        a {
            text-decoration: none;
            color: inherit;
        }
        &:hover {
            cursor: pointer;
            background: $primary-4;
            box-shadow: 0 0 4px 2px $primary-3;
            div {
                color: $primary-1;
            }
        }
    }

    .invoice-card {
        .card-body {
            background: $primary-6;
        }
    }
    .invoice-company-image-container {
        background: $primary-2;
        width: 100%;
        height: 100px;
        text-align: center;
    }
    .invoice-company-image {
        display: inline-block;
        width: 200px;
        height: 100px;
    }
    .invoice-task-list-editable {
        .invoice-add-item {
            transition: all 0.3s ease;
            border-radius: 3px;
            position: absolute;
            right: 10px;
            top: 10px;
            box-shadow: 0 0 3px 2px $primary-2;
            background: $primary-4;
            padding: 5px;
            width: 20px;
            height: 20px;
            &:hover {
                cursor: pointer;
                background: $primary-3;
            }
            div {
                font-size: 24px;
                margin-top: -14px;
                margin-left: -2px;
            }
        }
        .invoice-task-item-editable {
            .delete-invoice-item {
                position: absolute;
                opacity: 0;
                transition: all 0.3s ease;
                width: 17px;
                padding-left: 5px;
                padding-right: 5px;
                margin-left: -35px;
                color: white;

            }

            &:hover {
                .delete-invoice-item {
                    display: block;
                    opacity: 1;
                    position: absolute;
                    margin-left: -35px;
                    box-shadow: 0 0 3px 2px $danger-1;
                    background: $danger-3;
                    color: white;
                    padding-left: 5px;
                    padding-right: 5px;
                    border-radius: 3px;
                    &:hover {
                        cursor: pointer;
                        background: $danger-1;
                    }
                }
            }
        }
        .invoice-new-item-form {
            display: none;
            &.active {
                display: block;
            }
            .add-invoice-item {
                transition: all 0.3s ease;
                display: block;
                opacity: 1;
                position: absolute;
                margin-left: -35px;
                margin-top: 38px;
                font-weight: bold;
                box-shadow: 0 0 3px 2px $primary-2;
                background: $primary-4;
                padding-left: 5px;
                padding-right: 5px;
                border-radius: 3px;
                &:hover {
                    cursor: pointer;
                    background: $primary-3;
                }
            }
            .delete-invoice-item {
                display: block;
                opacity: 1;
                position: absolute;
                margin-left: -35px;
                margin-top: 8px;
                box-shadow: 0 0 3px 2px $danger-1;
                background: $danger-3;
                color: white;
                padding-left: 5px;
                padding-right: 5px;
                border-radius: 3px;
                &:hover {
                    cursor: pointer;
                    background: $danger-1;
                }
            }
            textarea {
                height: 50px!important;
            }
        }
    }

    .mar-l10-on-wide {
        margin-left: 10px;
        @media only screen and (max-width: 700px) {
            margin-left: 0px;
        }
    }

    .originating-vendor-img-container {
        box-shadow: 0 0 4px 1px $primary-2;
        background: $primary-2;
        margin: auto;
        margin-top:20px;
        width: 300px;
        height: 200px;
    }

    .vendor-add-task-button {
        box-shadow: 0 0 3px 1px $primary-2;
        background: $primary-3;
        &.wiggle {
            animation: infinite 3s wiggle;
            transition: all 0.3s ease;
        }
        &:hover {
            background: $primary-2;
        }
    }

    .search-for-navbar{
        padding: 0px;
        li {
            transition: all 0.4s ease;
            display: inline-block;
            padding: 10px; 
            background: $primary-4;
            box-shadow: 0 0 3px 1px $primary-3;
            border-radius: 10px;
            margin-left: 10px;
            margin-top: 15px;
            &:hover {
                cursor: pointer;
                background: $primary-3;
            }
            &.active {
                box-shadow: 0 0 3px 3px $primary-2;
                background: $primary-3;
                filter: brightness(110%);
                &:hover {
                    cursor: pointer;
                    background: $primary-3;
                    filter: brightness(110%);
                }
            }
        }
    }

    .proj-back-button {
        position: absolute;
        right: 15px;
        top: 5px;
    }
    .shorten-project-title-spacing {
        width: calc(100% - 45px);
        padding-left: 45px;
        .left {
            display: inline-block;
            width: 100px;
            vertical-align: top;
        }
        .right {
            display: inline-block;
            width: calc(100% - 100px);
        }
    }

    .expandable-hover {
        transition: all 0.3s ease;
        &:hover {
            cursor: pointer;
            background-color: $primary-2;
        }
    }
    .collapsed {
        transition: all 0.3s ease;
        max-height: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        overflow: hidden;
    }
    .prop-status.status-circle {
        height: 10px;
        width: 6px;
        position: absolute;
        left: 5px;
        top: 5px;
        border-radius: 5px;
    }
    .prop-back-button {
        float: right;
        margin-top: -10px;
    }
    .hide-visibility {
        position: absolute;
        right: 15px;
        top: 24px;
        opacity: 0;
        &.adjust-left {
            right: 55px;
        }
    }
    
    .props-menu-location {
        position: absolute;
    }
    .prop-list {
        .card-header {
            min-height: 60px;
            max-height: 1000px;
            padding-top: 1px;
            &:hover {
                box-shadow: 0 0 3px 1px $primary-2;
            }
        }
        .accept {
            transition: all 0.3s ease;
            position: absolute;
            right: 50px;
            height: 25px;
            width: 25px;
            background-color: $success-2;
            box-shadow: 0 0 2px 1px $primary-1;
            border-radius: 5px;
            font-size: 18px;
            padding-left: 4px;
            margin-top: 5px;
            &:hover {
                background-color: $success-3;
                cursor: pointer;
            }
        }
        .reject {
            transition: all 0.3s ease;
            position: absolute;
            right: 10px;
            height: 25px;
            width: 25px;
            margin-top: 5px;
            background-color: $danger-1;
            box-shadow: 0 0 2px 1px $primary-1;
            border-radius: 5px;
            font-size: 18px;
            padding-left: 4px;
            &:hover {
                background-color: $danger-2;
                cursor: pointer;
            }
        }
        .prop-title {
            font-weight: bold;
        }
        .prop-files {
            padding-bottom: 15px;
        }
        .prop-received {
            margin-left: 20px;
            width: calc( 100% - 95px);
        }
    }

    .proposal-left-input {
        display: inline-block;
        width: calc(100% - 175px);
        margin-right: 5px;
    }
    .proposal-right-input {
        display: inline-block;
        width: 170px;
    }
    .currency-overlay {
        width: 100%;
        text-align: center;
        font-size: 15px;
        padding-bottom: 10px;
    }
    .file-uploader {
        height: 0.1px;
        width: 0.1px;
        position: absolute;
        opacity: 0;
        overflow: hidden;
        z-index: -1;
    }
    .file-uploader + label {
        width: 100px;
        background-color: $primary-4;
        height: 35px;
        text-align: center;
        padding-top: 8px;
        font-weight: bold;
        cursor: pointer;
        border-radius: 10px;
        &:hover {
            background-color: $primary-3;
        }
    }
    .file-uploader-label {
        position: absolute;
        left: 125px;
        margin-top: -17px;
        font-style: italic;
        font-weight: 1;
    }
    .form-file-item {
        display: inline-block;
        background-color: $primary-6;
        box-shadow: 0 0 3px 1px $primary-4;
        border-radius: 10px;
        padding-left: 10px;
        padding-right: 10px;
        margin-left: 5px;
        margin-top: 5px;
        transition: all 0.3s ease;
        &:hover {
            background-color: $danger-3;
            cursor: pointer;
        }
        &.open-item:hover {
            background-color: $primary-3;
            cursor: pointer;
        }
    }
    textarea {
        min-height: 300px;
    }

    .bid-task-description {
        background-color: $primary-5!important;
        filter: brightness(95%)!important;
    }

    .task-status {
        height: calc(100% - 5px);
        width: 5px;
        position: absolute;
        left: 2px;
        top: 3px;
        border-radius: 5px;
    }
    .task-description {
        width: calc(100% - 10px);
        margin-left: 10px;   
    }
    .task-description-text {
        width: calc(100% - 60px);
    }
    .task-resource-overview {
        position: absolute;
        top: 3px;
        right: 45px;
        width: 20px;
        height: 20px;
        img {
            border-radius: 100%;
            height: 20px;
            width: 20px;
        }
    }
    .menu-toggle {
        position: absolute;
        right: 0px;
        margin: 0px;
        padding: 0px;
        width: 40px;
        box-shadow: 0px 0px 3px 1px black;
        border-radius: 5px;
        > div {
            position: relative;
            margin-top: -15px;
            border-radius: 20px;
        }
        .dropdown-divider {
            border-top: 2px solid $primary-4;
        }
        .menu-pointer {
            height: 15px;
            width: 15px;
            position: absolute;
            right: -5px;
            top: 20px;
            transform: rotate(45deg);
            background-color: white;
        }
        .menu-span{
            color: black!important;
            position: relative;
            top: -5px;
        }
        .dropdown-item {
            border-radius: 10px;
        }
    }
    .menu-button {
        width: 30px;
        height: 16px;
        position: absolute;
        right: 5px;
        top: 6px;
        border-radius: 5px;
        font-size: 24px;
        background-color: $primary-3;
        &:hover {
            cursor: pointer;
            background-color: $primary-4;
        }
        span {
            position: relative;
            top: -17px;
        }
    }

    .status-circle-profile {
        height: 40px;
        width: 40px;
        border-radius: 5px;
        img {
            border-radius: 15px;
        }
    }
    .add-border-bottom {
        border-bottom: 1px solid $primary-3;
    }
    .resource-line {
        border-bottom: 1px solid $primary-3;
        transition: filter 0.3s ease;
        filter: saturate(0.8);
        &:hover {
            transition: filter 0.3s ease;
            filter: saturate(1.2);
        }
    }
    .resource-carousel {
        @media only screen and (min-width: 775px) {
            display: none;
        }
        .status-circle-profile {
            position: relative;
            margin-left: -10px;
        }        
    }
    .resource-name {
        padding-left: 10px;
        width: calc(100% - 50px);
        font-weight: bold;
    }
    &.left-dashboard {
        //max-width: 600px;
        @media only screen and (max-width: 700px) {
            max-width: 100%;
        }   
    }
    &.right-dashboard {
        max-width: 250px;
        @media only screen and (max-width: 775px) {
            display: none;
        }
    }
    .card {
        // background: $primary-3;
        box-shadow: 0 0 2px 2px $primary-2;
        .card {
            box-shadow: none;
        }
        font-size: 12px;
    }
    .card-header {
        max-height: 42px;
        padding: 5px 0px 5px 0px;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        &.new-task-header {
            min-height: 42px;
            max-height: 1000px;
        }
    }
    .card-body {
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
    }
    .chart-box {
        padding: 0px;
        width: 100%;
        height: 50%;
    }
    .hide-on-compact {
        @media only screen and (min-width: 775px) {
            display: none;
        }
    }
    .bid-task-overview {
        min-height: 20px;
        background-color: $primary-5;
        padding-left: 15px;
        box-shadow: 0 0 2px 2px $primary-3;
        margin-top: 2px;
    }
    .bid-tasks-container {
        background-color: $primary-4;
        box-shadow: 0 0 3px 1px $primary-3;
    }
    .bid-indicator-1{
        // filter: grayscale(100%);
        filter: brightness(80%);
    }

    .indent- {
        &0 {
            .card-body {
                background-color: $primary-4;
            }
        }
        $offset: 15px;
        &1 {
            margin-left: $offset * 1;
        }
        &2 {
            margin-left: $offset * 2;
        }
        &3 {
            margin-left: $offset * 3;
        }
        &4 {
            margin-left: $offset * 4;
        }
        &5 {
            margin-left: $offset * 5;
        }
        &6 {
            margin-left: $offset * 6;
        }
        &7 {
            margin-left: $offset * 7;
        }
        &8 {
            margin-left: $offset * 8;
        }
        &9 {
            margin-left: $offset * 9;
        }
        &10 {
            margin-left: $offset * 10;
        }
    }
    .task-list-expand-button {
        position: absolute;
        z-index: 5;
        width: calc(100% - 63px);
        margin-left: 10px;
        height: 29px;
        &:hover {
            background-color: rgba( $primary-3, .3);
            cursor: pointer;
        }
    }
    .collapsible-task-list {
        transition: all .6s ease;
        background-color: $primary-3;
        max-height: 2000px;
        &.collapse-tasks {
            max-height: 0px;
            padding: 0px;
            color: transparent;
            transition: all 0.3s ease;
            overflow: hidden;
        }
    }

    .bid-view-expand-button {
        &:hover {
            background-color: $primary-1;
        }
        .card-body:hover {
            cursor: pointer;
        }
    }

}

.btn-fade-in {
    transition: all 0.4s ease;
    animation: .3s ease-in 0s 1 fade-in;
}

.vendor-portal-container {
    margin-top: 5px;
    background: $primary-gradient-0;
    box-shadow: 0 0 5px 1px $primary-0;
    border-radius: 5px;
    margin-left: 1px;
    padding-top: 2px;

    .profile-not-yet-created {
        animation-name: fade-in-and-fade-out;
        animation-delay: 1s;
        animation-iteration-count: 1;
        animation-duration: 5s;
        opacity: 0;
        border-radius: 5px;
        position: absolute;
        top: 45px;
        min-height: 50px;
        min-width: 200px;
        box-shadow: 0 0 10px 2px $primary-1;
        background: $primary-6;
        z-index: 1000;
        .caret {
            transform: rotate(45deg);
            top: -5px;
            left: 30px;
            background: $primary-6;
            z-index: 1050;
            box-shadow: -2px -2px 14px 1px $primary-1;
            position: absolute;
            min-width: 15px;
            min-height: 15px;
        }
        .content-top {
            border-radius: 5px;
            position: absolute;
            left: 0px;
            padding-top: 15px;
            min-height: 40px;
            min-width: 190px;
            z-index: 1100;
            display: inline-block;
            background: $primary-6;
        }
        &.signed-up {
            display: none;
        }
    }

    .user-profile-cards-list {
        list-style: none;
        margin-left: none;
        padding-left: 0;

        li {
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: 1px solid $primary-2;
            width: 100%;
        }

        .item-brand-container {
            position: absolute;
            margin-top: 0px;
            height: 30px;
            width: 50px;
            box-shadow: 0 0 3px 1px $primary-1;
            border-radius: 5px;
            font-weight: bold;
            img {
                border-radius: 5px;
            }
        }

        .default-checkbox {
            position: absolute;
            margin-top: -30px;
            margin-left: 180px;
        }

        .item-details-container {
            width: 100px;
            margin-left: 60px;
        }
        .item-details-expdate {
            width: 100px;
            margin-left: -5px;
        }

        .delete-card {
            position: absolute;
            right: 15px;
        }
    }


    .expandable-list {
        transition: all 0.2s ease;
        max-height: 100px;
        &.collapsed {
            transition: all 0.2s ease;
            max-height: 0px;
            padding-top: 0px;
            padding-bottom: 0px;
            overflow: hidden;
        }
    }
    

    &.right-dashboard {
        max-width: 250px;
        @media only screen and (max-width: 775px) {
            display: none;
        }
    }
 

    &.left-dashboard {
        min-width: 275px;
        max-width: calc(100% - 5px);
        @media only screen and (max-width: 700px) {
            max-width: 100%;
        }   
    }
    .hide-on-desktop-view {
        @media only screen and (min-width: 775px) {
            display: none;
        }
    }
    .hide-on-mobile-view {
        @media only screen and (max-width: 775px) {
            display: none;
        }
    }
    .card {
        font-size: 12px;
        box-shadow: 0 0 2px 2px $primary-2;
    }
    .card-body {
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
    }
    .card-header {
        max-height: 42px;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        padding: 5px 0px 5px 0px;
    }
    .left-inner-search-div {
        position: absolute;
        width: 75px;
        display: inline-block;
        z-index: 2;
        input {
            z-index: 2;
        }
        button {
            margin-top: 10px;
        }
    }
    .right-inner-search-div {
        height: 110px;
        width: calc(100% - 80px);
        min-width: 165px;
        overflow: hidden;
        margin-left: 90px;
        margin-top: -10px;
        z-index: 1;
    }
    .search-button-container {
        min-width: initial;
    }

    .expand-on-click {
        transition: all .5s ease;
        width: 73px;
        &:focus {
            width: calc(100vw - 325px);
        }
    }
    .info-header {
        font-weight: bold;
        border-bottom: 1px solid $primary-3;
    }
    .info-details {
        padding-left: 15px;
    }
    .bookmark-results {
        .card {
            box-shadow: 0 0 2px 1px $primary-3;
            min-width: 210px;
        }
        .card-header {
            background-color: $primary-3;
            height: 50px!important;
            min-height: 50px!important;
            max-height: 50px!important;
            &:hover {
                background-color: $primary-2;
            }
        }
        .card-img {
            position: absolute;
            left: 5px;
            height: 40px;
            width: 40px;
            img {
                border-radius: 5px; 
            }
            
        }
        .card-title {
            height: 40px;
            width: calc(100% - 60px);
            position: absolute;
            left: 55px;
            font-weight: bold;
            text-shadow: 0 1px 3px $primary-2;
        }
        .card-body {
            transition: all 0.3s ease;
            max-height: 400px;
            padding: 0px;
            background-color: $primary-4;
            .card-details {
                justify-content: center;
                margin: 0;
                width: 100%;
            }
            .col {
                max-width: 300px;    
            }
        }
    }

    .search-results {
        .card {
            min-width: 210px;
            box-shadow: 0 0 5px 1px $primary-3;
        }
        .card-header {
            transition: all 0.4s ease;
            background-color: $primary-3;
            height: 50px!important;
            min-height: 50px!important;
            max-height: 50px!important;
            &:hover {
                background-color: lighten($primary-2, 10%);
            }
        }
        .card-img {
            position: absolute;
            left: 5px;
            height: 40px;
            width: 40px;
            div {
                box-shadow: 0 0 0 1px $primary-2;
                background-color: rgba($primary-2, .5);
                border-radius: 5px; 
                background-position: center;
            }
        }
        .card-title {
            height: 20px;
            width: calc(100% - 95px);
            position: absolute;
            left: 60px;
            font-weight: bold;
            text-shadow: 0 1px 3px $primary-2;
            font-size: 12px;
            text-align: left;
            padding-top:3px;
            &.subtitle {
                font-size: 10px;
                font-weight: none;
                margin-top: 17px;
                padding-left: 0px;
                text-shadow: 0 0 1px white;
            }
        }
        .subtitle {
            &-0 {
                height: 20px;
                width: calc(100% - 95px);
                position: absolute;
                left: 60px;
                font-weight: bold;
                text-shadow: 0 1px 3px $primary-2;
                font-size: 12px;
                text-align: left;
                padding-top:3px;
                margin-top: -5px;
            }
            &-1 {
                font-size: 10px;
                font-weight: none;
                margin-top: 11px;
                padding-left: 0px;
                text-shadow: 0 0 1px white;
            }
            &-2 {
                font-size: 10px;
                font-weight: none;
                margin-top: 25px;
                padding-left: 0px;
                text-shadow: 0 0 1px white;
            }
        }
        .card-body {
            transition: all 0.3s ease;
            max-height: 400px;
            padding: 0px;
            background-color: $primary-4;
            .card-details {
                justify-content: center;
                margin: 0;
                width: 100%;
            }
            .col {
                max-width: 300px;    
            }
            button {
                padding: 3px 20px 3px 20px;
            }
        }
        .collapsed {
            transition: all 0.3s ease;
            max-height: 0px;
            padding-top: 0px;
            padding-bottom: 0px;
            overflow: hidden;
        }
    }
    
    .customer-page-header {
        text-align: center;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        text-shadow: 0px -1px 1px $primary-2, 0px 1px 1px $primary-1;
    }
    .customer-image-container {
        height: 200px;
        width: 300px;
        display: inline-block;
        img {
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
        }
    }
    .customer-header {
        font-size: 24px;
        font-weight: bold;
        padding: 15px 0px 15px 0px;
    }
    .customer-page-main-body {
        margin-top: -20px;
        .nav-tabs {
            position: relative;
            top: 20px;
            &:hover {
                cursor: pointer;
                .active {
                    cursor: default;
                }
            }
        }
        .nav-item {
            position: relative;
            left: -5px;
        }
    }
    .bus-profile-buttons {
        min-height: 40px;
        margin-top: 20px;
        text-align: center;
    }

    .image-checking-stripe {
        width: 30px;
        height: 30px;
        margin-right: 10px;
    }

    .profile-details- {
        $border-variable: $primary-3 1px solid;
        $box-shadow-variable: 0px 1px 2px 1px $primary-3;
        $border-radius-variable: 5px;
        $change-on-compact: 650px;
        &business-name {
            border: $border-variable;
            border-radius: $border-radius-variable;
            box-shadow: $box-shadow-variable;
            text-shadow: 0px -1px 1px $primary-2, 0px 1px 1px $primary-1;
            @media only screen and (max-width: $change-on-compact) {
                width: 100%;
            }
        }
        &industry {
            border: $border-variable;
            border-radius: $border-radius-variable;
            box-shadow: $box-shadow-variable;
            width: 270px;
            @media only screen and (max-width: $change-on-compact) {
                width: 100%;
            }
        }
        &description {
            border: $border-variable;
            border-radius: $border-radius-variable;
            box-shadow: $box-shadow-variable;
            width: 270px;
            @media only screen and (max-width: $change-on-compact) {
                width: 100%;
            }
        }
        &addressName {
            border: $border-variable;
            border-radius: $border-radius-variable;
            box-shadow: $box-shadow-variable;
            width: 270px;
            @media only screen and (max-width: $change-on-compact) {
                width: 100%;
            }
        }
        &addressOne {
            border: $border-variable;
            border-radius: $border-radius-variable;
            box-shadow: $box-shadow-variable;
            width: 135px;
            @media only screen and (max-width: $change-on-compact) {
                width: 50%;
            }
        }
        &addressTwo {
            border: $border-variable;
            border-radius: $border-radius-variable;
            box-shadow: $box-shadow-variable;
            width: 135px;
            @media only screen and (max-width: $change-on-compact) {
                width: 50%;
            }
        }
        &addressCity {
            border: $border-variable;
            border-radius: $border-radius-variable;
            box-shadow: $box-shadow-variable;
            width: 120px;
            @media only screen and (max-width: $change-on-compact) {
                width: 50%;
            }
        }
        &addressState {
            border: $border-variable;
            border-radius: $border-radius-variable;
            box-shadow: $box-shadow-variable;
            width: 50px;
            height: 21px;
            @media only screen and (max-width: $change-on-compact) {
                width: 20%;
            }
        }
        &addressPostal {
            border: $border-variable;
            border-radius: $border-radius-variable;
            box-shadow: $box-shadow-variable;
            width: 100px;
            @media only screen and (max-width: $change-on-compact) {
                width: 30%;
            }
        }
    }

    .dropzone {
        background-color: rgba(75, 75, 75, 0.493)!important;
    }
    .dropzone-container small {
        position: absolute!important;
        color: black!important;
        margin-top: -15px!important;
        margin-left: -30px!important;
    }

    .div-inner-container-for-file-upload {
        position: absolute;
        top: 0;
        
        &.edit-mode {
            position: absolute;
            width: auto!important;
            margin-left: calc(50% - 150px);
        }
    }
    .inner-container-for-file-upload {
        &.edit-mode {
            img {
            height: calc(100% - 25px);
            width: calc(100% - 25px);
            margin-left: -10px;
            }
        }
    }
    .single-bookmark {
        transition: all 0.4s ease;
        height: 30px;
        background: $primary-4;
        background-color: $primary-4;
        margin-left: -6px;
        .card-img {
            background-color: $primary-2;
            margin-top: 4px;
            box-shadow: 0 0 1px 1px $primary-2;
            height: 20px;
        }
        .card-title {
            padding-top: 7px;
            height: 28px;
            text-shadow: 0px 1px 1px $primary-2;
        }
        .remove-bookmark {
            background-color: $danger-2;
            transition: all 0.4s ease;
            width: 20px;
            height: 20px;
            color: white;
            text-align: center;
            position: absolute;
            right: 2px;
            top: 3px;
            border-radius: 5px;
            padding-top: 1px;
            opacity: 0;
            &.mobile-true {
                opacity: 1;
            }
            &:hover {
                background-color: $danger-1;
            }
        }
        &:hover  {
            cursor: pointer;
            background: $primary-3;
            background-color: $primary-3;
            .remove-bookmark {
                opacity: 1;
            }
        }
    }

    .single-customer-quicknav-buttons {
        position: absolute;
        margin-top: 5px;
        right: 5px;
        height: 40px;
        width: 20px;

        .bookmark-button {
            transition: all 0.5s ease;
            color: $primary-1;
            text-align: center;
            background: $primary-4;
            background-color: $primary-4;
            border-radius: 3px;
            margin-top: 5px;
            box-shadow: 0 0 3px 1px $primary-2;
            &:hover {
                background: $primary-3;
                background-color: $primary-3;
                transform: scale(1.1);
            }
        }

        .visit-button {
            transition: all 0.5s ease;
            color: $primary-1;
            text-align: center;
            background: $primary-4;
            background-color: $primary-4;
            border-radius: 3px;
            box-shadow: 0 0 3px 1px $primary-2;
            &:hover {
                background: $primary-3;
                background-color: $primary-3;
                transform: scale(1.1);
            }
        }

        .visit-button-bid {
            transition: all 0.5s ease;
            color: $primary-1;
            text-align: center;
            background: $primary-4;
            background-color: $primary-4;
            border-radius: 3px;
            box-shadow: 0 0 3px 1px $primary-2;
            margin-top: 0px;
            &:hover {
                background: $primary-3;
                background-color: $primary-3;
                transform: scale(1.1);
            }
        }
    }
    .search-for-navbar{
        padding: 0px;
        li {
            transition: all 0.4s ease;
            display: inline-block;
            padding: 10px; 
            background: $primary-4;
            box-shadow: 0 0 3px 1px $primary-3;
            border-radius: 10px;
            margin-left: 15px;
            margin-top: 5px;
            &:hover {
                cursor: pointer;
                background: $primary-3;
            }
            &.active {
                box-shadow: 0 0 3px 3px $primary-2;
                background: $primary-3;
                filter: brightness(110%);
                &:hover {
                    cursor: pointer;
                    background: $primary-3;
                    filter: brightness(110%);
                }
            }
        }
    }
}

.modal {
    .expanding-menu {
        transition: all 0.8s ease;
        max-height: 0px;
        overflow: hidden;
        white-space: nowrap;

        &.true {
            max-height: 1500px;
            overflow: hidden;
            white-space: nowrap;
        }
        &.false {
            max-height: 0px;
            display: none;
        }
    }
    .user-profile-cards-list {
        list-style: none;
        margin-left: none;
        padding-left: 0;

        li {
            transition: all 0.8s ease;
            padding-bottom: 10px;
            padding-top: 10px;
            padding-left: 50px;
            border-bottom: 1px solid $primary-2;
            width: 100%;
            &.default-selected-card {
                padding-bottom: 30px;
                &:hover {
                    box-shadow: 0px 0px 3px 1px $primary-2;
                }
            }
            &:hover {
                cursor: pointer;
                background: $primary-3;
                box-shadow: 0px 0px 3px 1px $success-2;
            }

        }
        .change-payment-method-link {
            margin-top: -30px;
            margin-left: 50px;
        }
    
        .item-brand-container {
            position: absolute;
            margin-top: 10px;
            height: 30px;
            width: 50px;
            box-shadow: 0 0 3px 1px $primary-1;
            border-radius: 5px;
            font-weight: bold;
            img {
                border-radius: 5px;
            }
        }
        .item-details-container {
            width: 100px;
            margin-left: 60px;
        }
        .item-details-expdate {
            width: 100px;
            margin-left: -5px;
        }
    }
}
