.registration-container {
    // div {
    //     box-shadow: 0 0 0 1px black;
    // }
    .card {
        border-radius: 15px;
        box-shadow: 1px 1px 6px 3px $primary-2;
    }
    .col {
        padding-bottom: 5px;
    }
    .left-side-col {
        padding-right: 20px;
        max-width: 30px;
        display: inline-block;
        input {
            margin-left: -10px;
            margin-top: -15px;
            position: absolute;
            @media only screen and (max-width: 500px) {
                margin-top: -35px;
            }
        }
    }
    .option-label {
        max-width: calc(100% - 30px);
        display: inline-block;
    }
    .registration-header {
        button {
            position: relative;
            top: 60px;
            right: -10px;
        }
        min-height: 100px;
    }
    .registration-form-content {
        min-height: calc(100vh - 150px);
    }
    .fade-out {
        opacity: 0;
        transition-property: all;
        animation: 1s ease-in 0s fade-out; 
    }
    $delay: 3s;
    .text-0 {
        font-size: 20px;
        position: absolute;
        margin-top: 10px;
        width: calc(100% - 40px);
        max-height: 300px;
        opacity: 0;
        transition-property: all;
        animation: .3s ease-in s0s fade-in;
        &.stay {
            opacity: 1;
            transition-property: all;
            animation: 1s ease-in 0s fade-in;    
        }
    }
    .text-1 {
        font-size: 20px;
        position: absolute;
        margin-top: 25px;
        width: calc(100% - 40px);
        max-height: 300px;
        opacity: 0;
        transition-property: all;
        animation: 3.5s ease-in ($delay * 0) fade-in-and-out;
        &.stay {
            opacity: 1;
            transition-property: all;
            animation: 3s ease-in 0s fade-in-delayed;    
        }
    }
    .text-2 {
        font-size: 20px;
        position: absolute;
        margin-top: 25px;
        width: calc(100% - 40px);
        max-height: 300px;
        opacity: 0;
        transition-property: all;
        animation: $delay ease-in ($delay * 1) fade-in-and-out;
        &.stay {
            opacity: 1;
            transition-property: all;
            animation: 4s ease-in 0s fade-in-delayed;    
        }
    }
    .text-3 {
        font-size: 20px;
        position: absolute;
        margin-top: 25px;
        width: calc(100% - 40px);
        max-height: 300px;
        opacity: 1;
        transition-property: all;
        animation: $delay ease-in ($delay * 2) fade-in-and-out;
        &.stay {
            opacity: 1;
            transition-property: all;
            animation: 8s ease-in 0s fade-in-delayed;    
        }
    }

    .underline-only {
        border: none;
        border-bottom: $primary-3 1px solid;
    }

    label.move-to-on-top {
        font-size: 12px;
        &.menu3 {
            margin-left: 0px;
            left: 25px;
        }
    }
    .display-next-button {
        margin-top: 10px;
        float:right;
        opacity: 1;
        transition-property: all;
        animation: .5s ease-in 0s fade-in; 
    }

    .option-0 {
        opacity: 1;
        transition-property: all;
        animation: 6.4s ease-in 0s fade-in-delayed;
        &.off-1 {
            animation: 3.4s ease-in 0s fade-in-delayed;
        }
        &.off-2 {
            animation: 2.4s ease-in 0s fade-in-delayed;
        }
        &.off-3 {
            animation: 1.4s ease-in 0s fade-in-delayed;
        }
    }

    .option-1 {
        opacity: 1;
        transition-property: all;
        animation: 10.4s ease-in 0s fade-in-delayed;
        &.off-1 {
            animation: 7.4s ease-in 0s fade-in-delayed;
        }
        &.off-2 {
            animation: 6.4s ease-in 0s fade-in-delayed;
        }
        &.off-3 {
            animation: 5.4s ease-in 0s fade-in-delayed;
        }
    }
    .option-2 {
        opacity: 1;
        transition-property: all;
        animation: 10.8s ease-in 0s fade-in-delayed;
        &.off-1 {
            animation: 7.8s ease-in 0s fade-in-delayed;
        }
        &.off-2 {
            animation: 6.8s ease-in 0s fade-in-delayed;
        }
        &.off-3 {
            animation: 5.8s ease-in 0s fade-in-delayed;
        }
    }
    .option-3 {
        opacity: 1;
        transition-property: all;
        animation: 11.2s ease-in 0s fade-in-delayed;
        &.off-1 {
            animation: 8.2s ease-in 0s fade-in-delayed;
        }
        &.off-2 {
            animation: 7.2s ease-in 0s fade-in-delayed;
        }
        &.off-3 {
            animation: 6.2s ease-in 0s fade-in-delayed;
        }
    }
    .option-4 {
        opacity: 1;
        transition-property: all;
        animation: 11.6s ease-in 0s fade-in-delayed;
        &.off-1 {
            animation: 8.6s ease-in 0s fade-in-delayed;
        }
        &.off-2 {
            animation: 7.6s ease-in 0s fade-in-delayed;
        }
        &.off-3 {
            animation: 6.6s ease-in 0s fade-in-delayed;
        }
    }

    input.text-red {
        border: 1px solid red;
    }
    small {
        display: none;
        &.text-red {
            color: red;
            display: inline;
        }
    }
}

@keyframes fade-in-and-out {
    0% {
        transform: translate(0px, -5px);
        opacity: 0;
    }
    25% {
        transform: translate(0px, 0px);
        opacity: 1;
    }

    80% {
        transform: translate(0px, 0px);
        opacity: 1;
    }
    100% {
        transform: translate(0px, 0px);
        opacity: 0;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fade-in {
    0% {
        transform: translate(0px, -5px);
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fade-in-delayed {
    0% {
        transform: translate(0px, -5px);
        visibility: hidden;
        opacity: 0;
    }
    90% {
        transform: translate(0px, -5px);
        visibility: visible;
        opacity: 0;
    }
    100% {
        transform: translate(0px, 0px);
        opacity: 1;
    }
}