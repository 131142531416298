.sidebar-component {
    margin-top: 5px;
    padding-bottom: 5px;
    margin-left: 0px;
    width: 250px;
    @media only screen and (max-width: $compact-view) {
        width: calc(100vw - 1%);
        margin-left: 0px;
    }
    .menu-routes-container {
        a { 
            color: $primary-1;
            text-shadow: 0 0 0px $primary-1;
            transition: color text-shadow 0.5s ease;
            text-decoration: none;
            &:visited {
                color: darken($primary-1, 5%);
            }
        }
    }

    .side-menu-nav-item {
        padding-top: 10px;
        .extender {
            transition: all 1.6s ease;
            height: 1px;
            margin-top: -2px;
            position: absolute;
            background: $primary-5;
            box-shadow: 0 0 0px 0px $primary-5;
            width: 0%;
        }
        &:hover {
            text-decoration: none;
            color: $primary-0!important;
            text-shadow: 0 0 1px $primary-1;
            .extender  {
                transition: all .6s ease;
                height: 1px;
                margin-top: -2px;
                position: absolute;
                background: $primary-3;
                box-shadow: 0 0 3px 2px $primary-3;
                width: 80%;
            }
        }
        
    }
    .outer-border {
        border: 1px solid $primary-1;
        box-shadow: 0 0 5px 1px $primary-0;
        border-radius: 5px;
        max-height: 100%;
        width: 100%;
        padding-top: 5px;
        padding-left: 5px;
        padding-bottom: 5px;
        background: $primary-gradient-0;
    }
    .inner-border {
        border-radius: 10px;
        width: calc(100% - 5px);
    }
    .card {
        background-color: transparent;
        border: 1px solid transparent;
        border-radius: 5px;
    }
    .card-header {
        text-align: left;
        padding: 3px;
        padding-left: 10px;
        &:hover {
            background-color: $primary-2;
        }
    }
    .card-body {
        transition: all 0.5s ease;
        padding-top: 5px;
        padding-bottom: 10px;
    }
    .collapsed {
        transition: all 0.5s ease;
        max-height: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        overflow: hidden;
    }

    transition: margin-top 0.4s ease;
    &.sidebar-off {
        @media only screen and (max-width: $compact-view) {
            transition: margin-top 0.4s ease;
            margin-top: -1000px;
            //            transform: translateY(-800px);        
        }
    }
}