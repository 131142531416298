@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;900&display=swap');

$compact-view: 575px;

$font-main: Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;
$font-title: 'Roboto', sans-serif;
body {
    background-color: $primary-3;
    background: $primary-gradient-2;
    min-height: 800px;
    overflow-x: hidden;
    font-family: 'Roboto', sans-serif;
    position: absolute!important;
    top: 0!important;

    .text-default {
        text-shadow: none;
        font-family: 'Roboto', sans-serif;
    }
}

.z- {
   &1 {
        z-index: 1;
   } 
   &2 {
        z-index: 2;
   }
   &3 {
        z-index: 3;
   }  
}
.btn:hover {
    cursor: pointer!important;
}
.modal {
    button {
        color: $primary-6;
    }
    button:hover {
        color: $primary-4;
    }
}

.modal-content {
    box-shadow: 0 0 3px 2px $primary-3;
}
.modal-header {
    border: none;
    color: $primary-6;
    background: $primary-0;
}
.modal-body {
    border: none;
    background: darken($primary-5, 2%);
}
.modal-footer {
    border:none;
    background: $primary-1;
    button {
        background: darken($primary-2,5%);
        &:hover {
            background: lighten($primary-2,1%);
        }
    }
}
.content-spacer {
    height: 250px;

}
@keyframes error-glow {
    0% {
        transition: filter 0.4s ease;
        background: radial-gradient(circle, $error-3 20%, $error-1 80%);
        filter: brightness(130%);
    } 50% {
        transition: filter 0.4s ease;
        background: radial-gradient(circle, $error-3 20%, $error-1 80%);
        filter: brightness(90%);
    } 100% {
        transition: filter 0.4s ease;
        background: radial-gradient(circle, $error-3 20%, $error-1 80%);
        filter: brightness(130%);
    }
}

.status-circle, .goto-circle {
    border-radius: 100%;
    box-shadow: 0px 0px 3px 1px black;
    text-align: center;
    &.success { 
        background: radial-gradient(circle, $success-3 20%, $success-1 80%);
    }
    &.error {
        background: radial-gradient(circle, $error-3 20%, $error-1 80%);
        &-glow {
            animation-duration: 1s;
            animation-name: error-glow;
            animation-iteration-count: infinite;
        }
    }
    &.danger {
        background: radial-gradient(circle, $danger-3 20%, $danger-1 80%);
    }
}

.stripe-button-container {
    width: 200px;
    &:hover {
        cursor: pointer;
    }
}
.stripe-payment-form {
    width: 100%;
    text-align: center;
    input, select {
        border: 1px solid $primary-2;
        display: inline-block;
        text-align: left;
        max-width: 500px;
    }

    .city {
        display: inline-block;
        width: calc(50%)!important;
        max-width: calc(500px - 250px);
    }
    .state {
        display: inline-block;
        width: calc(20%);
        max-width: calc(500px - 395px);
    }
    .zip {
        display: inline-block;
        width: calc(30%);
        max-width: calc(500px - 345px);
    }
}
.StripeElement {
    display: inline-block;
    height: 40px;
    padding: 10px 12px;
    width: 100%;
    max-width: 500px;
    color: $primary-3;
    background-color: white;
    border: 1px solid $primary-2;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
}

.vendor-stripe-signup-modal {
    margin-top: 100px;
}

ul.nav-tabs {
    list-style: none;
    padding: 0px;
    li.nav-item {
        display: inline-block;
        background-color:$primary-3;
        border: 1px solid $primary-3;
        border-radius: 15px 15px 0px 0px;
        padding: 5px 15px 5px 15px;
        &:hover {
            background-color:$primary-4;
        }
        &.active {
            background-color: $primary-4;
        }
    }
}

.overlay-container {
    width: 100%;
    min-width: 500px;
    height: 100vh;
    text-align: center;
    padding-top: 220px;
    .image-container {
        display: inline-block;
        width: 75px;
        height: 75px;
    }
}

.site-container {
    background-color: $primary-4;
    background: linear-gradient(90deg, $primary-3 18%, $primary-4 46%, $primary-5 86%);
    background-repeat: no-repeat;
    min-height: 100vh;
    > .row {
        margin: 0;
    }

    .card {
        background: $primary-3;
        border: 1px solid $primary-2;
        &-header {
            background-color: $primary-3;
        }
        &-body {
            background-color: $primary-5;
        }
        &-footer {
            background-color: $primary-4;
        }
    }
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.disabled-true:hover {
    cursor: not-allowed!important;
}
.checkout-expand-all-false {
    transition: all 0.5s ease;
    max-height: 200px;
    &.wasSuccess-true {
        max-height: 0px;
        overflow: hidden;
        white-space: nowrap;
        li {
            position: relative!important;
        }
        div {
            position: relative!important;
        }
    }
}
.checkout-button {
    transition: all .5s ease;
    max-height: 100px;

    .processing-true {
        &:hover {
            background: inherit;
            cursor: wait!important;
        }

        &.success-true {
            background: inherit;
            cursor: not-allowed!important;
        }
    }
    &.checkout-collapse- {
        &true {
            max-height: 0px;
            overflow: hidden;
            white-space: nowrap;
        }
        &false {
            max-height: 100px;
        }
    }
}

.loading-spinner {
    -webkit-animation: rotating .8s linear infinite;
    -moz-animation: rotating .8s linear infinite;
    -ms-animation: rotating .8s linear infinite;
    -o-animation: rotating .8s linear infinite;
    animation: rotating .8s linear infinite;

}

@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}