// Quick formatting
//borders
.min-width {
    &-15px {
        min-width: 30px;        
    }
    &-75px {
        min-width: 75px;
        word-wrap: normal;
        white-space: pre-wrap;
    }
    &-125px {
        min-width: 125px;
        word-wrap: normal;
        white-space: pre-wrap;
    }
    &-150px {
        min-width: 150px;
        word-wrap: normal;
        white-space: pre-wrap;
    }
    &-200px {
        min-width: 200px;
        word-wrap: normal;
        white-space: pre-wrap;
    }
}
.quick-btn {
    transition: all 0.4s ease;
    border-radius: 5px;
    padding: 2px 5px 2px 5px;
    &:hover {
        background: $primary-6;
        box-shadow: 0 0 3px 1px $primary-2;
        cursor: pointer;
    }
}
.max-width {
    &-300 {
        max-width: 300px;
        word-wrap: normal;
        white-space: pre-wrap;
    }
}
.border {
    &-shadow-primary {
        &-2 {
            box-shadow: 0 0 2px 1px $primary-2;
        }
    }
    &-red {
        box-shadow: 0 0 2px 1px $danger-1!important;
    }

    border: 1px solid $primary-3;
    &-t {
        border-top: 1px solid $primary-3;
    }
    &-r {
        border-right: 1px solid $primary-3;
    }
    &-b {
        border-bottom: 1px solid $primary-3;
    }
    &-l {
        border-left: 1px solid $primary-3;
    }
    &-red {
        border: 1px solid $danger-1;
    }
    &-none {
        border: none;
    }
    &-glow {
        box-shadow: 0 0 3px 1px $primary-2;
    }
}

//display
.d {
    &-none {
        display: none;
    }
    &-inline {
        display: inline;
    }
    &-binline {
        display: inline-block;
    }
    &-b {
        display: block;
    }
}
//width
.w- {
    &25 {
        width: 25%;
    }
    &50 {
        width: 50%;
    }
    &75 {
        width: 75%;
    }
    &100 {
        width: 100%;
    }
}
//margin
.mar- {
    &auto {
        margin: auto;
    }
    &0 {
        margin: 0px!important;
    }
    &5 {
        margin: 5px;
    }
    &10 {
        margin: 10px;
    }
    &15 {
        margin: 15px;
    }
    &t5 {
        margin-top: 5px;
    }
    &t10 {
        margin-top: 10px;
    }
    &t15 {
        margin-top: 15px;
    }
    &b5 {
        margin-bottom: 5px;
    }
    &b10 {
        margin-bottom: 10px;
    }
    &b15 {
        margin-bottom: 15px;
    }
    &l0 {
        margin-left: 0px!important;
    }
    &l5 {
        margin-left: 5px;
    }
    &l10 {
        margin-left: 10px;
    }
    &l15 {
        margin-left: 15px;
    }
    &r0 {
        margin-right: 0px!important;
    }
    &r5 {
        margin-right: 5px;
    }
    &r10 {
        margin-right: 10px;
    }
    &r15 {
        margin-right: 15px;
    }
}
//padding
.pad- {
    &0 {
        padding: 0px!important;
    }
    &5 {
        padding: 5px!important;
    }
    &10 {
        padding: 10px!important;
    }
    &15 {
        padding: 15px!important;
    }
    &t5 {
        padding-top: 5px!important;
    }
    &t10 {
        padding-top: 10px!important;
    }
    &t15 {
        padding-top: 15px!important;
    }
    &b5 {
        padding-bottom: 5px!important;
    }
    &b10 {
        padding-bottom: 10px!important;
    }
    &b15 {
        padding-bottom: 15px!important;
    }
    &l5 {
        padding-left: 5px!important;
    }
    &l10 {
        padding-left: 10px!important;
    }
    &l15 {
        padding-left: 15px!important;
    }
    &r5 {
        padding-right: 5px!important;
    }
    &r10 {
        padding-right: 10px!important;
    }
    &r15 {
        padding-right: 15px!important;
    }
}
//text
.filter- {
    &brightness- {
        &105 {
            filter: brightness(105%);
        }
        &70 {
            filter: brightness(70%);
        }
    }
    &blur {
        filter: blur(0.15em) brightness(90%);
    }
}

.text- {

    // Decorations
    &hide-overflow {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    &bold {
        font-weight: bold;
    }
    &white {
        color: white;
    }
    &blue {
        color: $primary-1;
    }
    &red {
        color: red;
        &:hover {
            color: red;
        }
    }
    &darkred {
        color: darkred;
    }
    &green {
        color: $success-2;
    }

    // Align
    &al {
        text-align: left;
    }
    &ac {
        text-align: center;
    }
    &ar {
        text-align: right;
    }


    // Font
    &sm {
        font-size: 10px;
    }
    &m {
        font-size: 12px;
    }
    &l {
        font-size: 14px;
    }
    &xl {
        font-size: 16px;
    }
}

.justify-{
    &c {
        justify-content: center;
    }
    &sc {
        justify-self: center;
    }
}