

.landing-page-main-container {

    a:hover {
      color: inherit;
      text-decoration: none;
    }

    .header-wrapper {
      width: 100vw;
      background-color:$primary-0;
      background-color: rgba($primary-0, .96);
      position: fixed;
      z-index: 99999999 !important;
      height: 92px;
      
      .header-menu {
        display: flex;
        position: relative;
        overflow-x: hidden;

        .header-img {
          width: 100px;
          margin: 20px;
        }

      .header-content {
          padding: 20px;
          padding-top: 38px;
          display: flex;
          width: 100%;
          align-items: center;

        li {    
            font-family: $font-main;
            color: $primary-6;
            font-weight: 700;
            list-style: none;
            margin-right: 30px;
            cursor: pointer;
            transition: 0.2s ease-in-out;

            &:hover {
                color: $primary-3;
            }
          }             
      }
    }
    .link-login {
      color: white;
      font-weight: 700;
      margin-right: 30px;
      &:hover {
        text-decoration: none;
        color: $primary-3;
      }
    }
    .show-on-mobile {
      display: none;
    }
    @media only screen and (max-width: 499px) {
      .hide-on-mobile {
        display: none;
      }
      .show-on-mobile {
        display: block;        
        .header-content {
          background-color:$primary-0;
          background-color: rgba($primary-0, .96);
          padding-top: 18px;
          width: 450px!important;
        }
        ul {
          display: inline-block!important;
          margin-left: -140px;
          padding-left: 35px!important;
        }
        li {
          display: inline-block;
          width: 100px!important;
        }
        width: 100%;
        margin-top: 92px;
      }
    }
  }
}
