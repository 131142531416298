//RETRO
// $primary-0: #28303a;
// $primary-1: #5e8f91;
// $primary-2: #7cbfb5;
// $primary-3: #afc6b8;
// $primary-4: #dcd9c4;
// $primary-5: #f0ded0;
// $primary-6: #ffffff;

//BLUE ORIGINAL
// $primary-0: #0b1725;
// $primary-1: #1d3249;
// $primary-2: #516b86;
// $primary-3: #93a9c0;
// $primary-4: #ccdbeb;
// $primary-5: #e0efff;
// $primary-6: #ffffff;

//BLUE UPDATED
$primary-0: #0b1725;
$primary-1: #314253;
$primary-2: #87a6c7;
$primary-3: #b5cde9;
$primary-4: #d0e2f5;
$primary-5: #eff7ff;
$primary-6: #ffffff;
$muted-0: rgb(39, 39, 39);
$muted-1: rgb(83, 83, 83);
$muted-2: rgb(151, 151, 151);
$primary-gradient-0: linear-gradient(0,$primary-0 35%, $primary-1 71%);
$primary-gradient-1: linear-gradient(0,$primary-1 30%, $primary-2);
$primary-gradient-2: linear-gradient(0,$primary-2 71%, $primary-3);
$primary-gradient-3: linear-gradient(0,$primary-3 71%, $primary-4);
$primary-gradient-4: linear-gradient(0,$primary-4 71%, $primary-5);
$danger-1: #d1292e;
$danger-2: #e26266;
$danger-3: #f87276;
$error-1: #756d00;
$error-2: #d8ca00;
$error-3: #fff45b;
$success-1: #0e4e00;
$success-2: #458f00;
$success-3: #5fc400;
$link-unvisited: darkblue;
$link-visited: rgb(44, 44, 250);
$link-hover: blue;


//Public group

$item-public-hover: rgb(147, 57, 231);



a, .link {
    color: $link-unvisited;
    &:hover {
        color: $link-hover;
    }
    &:visited {
        color: $link-visited;
        &:hover {
            color: $link-hover;
        }
    }
}

.primary{
    &-btn {
        box-shadow: 0 0 2px 1px $primary-3;
        background-color: $primary-4;
        &:hover {
            box-shadow: 0 0 2px 1px $primary-3;
            background-color: $primary-3;
        }
    }
    &-0 {
        background-color: $primary-0;
    }
    &-1 {
        background-color: $primary-1!important;
        &.btn:hover {
            background-color: $primary-0!important;
        }
    }
    &-2 {
        background-color: $primary-2!important;
        &.btn:hover {
            background-color: $primary-1!important;
        }
    }
    &-3 {
        background-color: $primary-3!important;
        &.btn:hover {
            background-color: $primary-2!important;
        }
    }
    &-4 {
        background-color: $primary-4!important;
        &.btn:hover {
            background-color: $primary-3!important;
        }
    }
    &-5 {
        background-color: $primary-5!important;
        &.btn:hover {
            background-color: $primary-4!important;
        }
    }
}
.danger{
    &-btn {
        background-color: $danger-3;
        &:hover {
            background-color: $danger-2;
        }
    }
    &-1 {
        background-color: $danger-1;
    }
    &-2 {
        background-color: $danger-2;
    }
}

.error{
    &-btn {
        background-color: $error-2;
        &:hover {
            background-color: $error-1;
        }
    }
    &-1 {
        background-color: $error-1;
    }
    &-2 {
        background-color: $error-2;
    }
}

.success{
    &-btn {
        background-color: $success-2;
        &:hover {
            background-color: $success-1;
        }
    }
    &-1 {
        background-color: $success-1;
    }
    &-2 {
        background-color: $success-2;
    }
}