.landing-page-main-container {
    .footer-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 50vh !important;
        background-color: $primary-0;
        align-items: center;
        
    }
    
    .footer-content {
        height: 100%;
        background-color: $primary-0;
        display: flex;
        justify-content: center;
    
        a {
            color:inherit;
        }
        a:hover {
            text-decoration: none;
        }
        
    
        ul {
            padding-top: 80px;
            color: $primary-6;
        }
    
        li {
            list-style: none;
            transition: 0.1s ease-in-out;
            &:hover {
                color: $item-public-hover;
                color: $primary-3;
            }
        }
    }

    .footer-break {
        width: 20%;
        background-color: $primary-6;
        height: 1px;
    }

    .footer-footer {
        color: $primary-6;
        padding: 30px;
        text-align: center;
    }
}
