.pb-login-form {
    width: 100%;
    position: relative;
    height: 50px;

    .pb-form-control {
        width: 100%;
        height: 100%;
        color: #595f62;
        padding-top: 20px;
        border: none;


       
    }
    .pb-form-label {
        position: absolute;
        bottom: 0px;
        left: 0;
        height: 100%;
        height: 100%;
    }
}

.login-modal-window {
    margin-top: 100px;
}

.container {

    .panels-container {
        .left-panel {
            @media only screen and (min-width: 870px) {
                .content {
                    padding-left: 90px;
                }
            }     
        }
    }
    &.sign-up-mode {
        .panels-container {
            .left-panel {
                .content {
                    padding-left:80px;
                }
                @media only screen and (min-width: 870px) {
                    .content {
                        padding-left: 50px;
                    }
                }    
                @media only screen and (min-width: 930px) {
                    .content {
                        padding-left: 70px;
                    }
                }    
                @media only screen and (min-width: 1000px) {
                    .content {
                        padding-left: 150px;
                    }
                }    
                
            }
            .right-panel {
                .content {
                    padding-left: 100px;
                }
            }
        }
    }
    
}
