.header-component {
    $header-min-height: 75px;
    width: calc(100vw);

    min-height: $header-min-height;
    background: $primary-gradient-1;
    .brand-logo {
        position: absolute;
        height: $header-min-height;
        width: 125px;
        left: 25px;
        top: 0px;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        &:hover {
            cursor: pointer;
        }
    }
    .hamburger {
        position: absolute;
        height: 40px;
        width: 40px;
        right: 5px;
        top: 20px;
        display: none;
        &:hover {
            cursor: pointer;
        }
        @media only screen and (max-width: $compact-view) {
            display: block;
        }
    }

    .profile {
        background: darken($primary-2, 25%);
        box-shadow: 0 0 2px 1px $primary-2;
        position: absolute;
        height: 40px;
        width: 40px;
        right: 85px;
        top: 20px;
        display: block;
        border-radius: 10px;
        background-position: 50% 50%;
        background-size: cover;
        img {
            border-radius: 10px;
        }
        &:hover {
            cursor: pointer;
        }
        @media only screen and (min-width: $compact-view) {
            right: 50px;
        }
    }

    .header-alert {
        color: white;
        font-size: 24px;
        padding-left: 10px;
        padding-top: 2px;
        position: absolute;
        height: 40px;
        width: 40px;
        right: 130px;
        top: 20px;
        display: block;
        border-radius: 10px;
        z-index: 900;
        &.wiggle {
            animation: wiggle2 infinite 3s;
        }
        img {
            border-radius: 10px;
        }
        &:hover {
            cursor: pointer;
        }
        @media only screen and (min-width: $compact-view) {
            right: 95px;
        }
    }
    .list-of-alerts {
        list-style: none;
        padding: 0 0 0 5px;
        margin: 0px;
        text-align: left;
        a:hover {
            text-decoration: none;
        }
        li {
            border-bottom: 1px $primary-4 solid;
            display: block;
            padding-left: 10px;
            padding-bottom: 10px;
            padding-top: 5px;
            margin-bottom: 5px;
            transition: all 0.4s ease;
            width: calc(100% - 10px);
            &:hover {
                cursor: pointer;
                background: darken($primary-5, 5%);
                box-shadow: 0 0 5px 3px darken($primary-5, 5%);
            }
        }
    }
    .alert-menu-open {
        position: absolute;
        background: $primary-5;
        color: black;
        box-shadow: 0 0 2px 1px $primary-2;
        width: 200px;
        margin-left: -180px;
        margin-top: 10px;
        z-index: 950;
        font-size: 20px;
        border-radius: 20px 20px 20px 20px;
        .alert-pointer {
            background: $primary-5;
            box-shadow: 0px -5px 5px 1px $primary-2;
            width: 10px;
            height: 15px;
            border-radius: 100% 0 0 0;
            position: absolute;
            right: 10px;
            margin-top: -10px;
            z-index: 925;
        }
    }
    .number-of-alerts {
        opacity: 1;
        transition: opacity 0.8s ease;
        background: $primary-0;
        color: white;
        font-size: 10px;
        text-align: center;
        width: 15px;
        height: 15px;
        position: absolute;
        right: 2px;
        margin-top: -15px;
        border-radius: 100%;
        box-shadow: 0 0 2px 1px $primary-3;
        &.fade-away {
            opacity: 0;
        }
    }

    .logout {
        position: absolute;
        height: 40px;
        width: 40px;
        top: 19px;
        right: 41px;
        display: block;
        border-radius: 10px;
        img {
            border-radius: 10px;
        }
        &:hover {
            cursor: pointer;
        }
        @media only screen and (min-width: $compact-view) {
            right: 5px;
        }
    }
    .nav-links {
        background-color: salmon;
        position: absolute;
        left: 140px;
        top: 15px;
        ul {
            margin-left: -30px;
            li {
                list-style: none;
                display: inline-block;
                padding-right: 25px;
            }
        }
    }
}