.landing-page-main-container {
    overflow-x: hidden!important;
    h1 {
        font-size: 35px;
    }
    body {
        background: none;
        background-color: $primary-5;
        font-family: $font-main;
    }
    
    .content-layer-one {
        width: 100%;
        height: 90vh;
        background-color: $primary-5;
        .welcome-text-wrapper {
            position: absolute;
            z-index: 99999;
            top: 200px;
            font-size: 12px;
            @media only screen and (max-width: 550px) {
                top: 225px;
                padding-left: 50px;
            }
            @media only screen and (min-width: 550px) {
                padding-left: 100px;
            }
            @media only screen and (min-width: 1000px) {
                padding-left: 200px;
            }
        
            .hero-text {
                text-shadow: 0px 1px 18px rgba(0, 0, 0, 0.418);
                color: $primary-6;
                font-family: $font-title;
                @media only screen and (max-width: 700px) {
                    font-size: 28px;
                }
                @media only screen and (max-width: 500px) {
                    font-size: 22px;
                }
            }
            .content-header-btn {
                margin-top: 20px;
                color: $primary-1;
                font-weight: 600;
                background-color: $primary-6;
                padding: 20px;
                margin-right: 50px;
                border: none;
                z-index: 999999 !important;;
                border-radius: 12px;
                transition: 0.3s ease-in-out;
                box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 16px;
                @media only screen and (max-width: 700px) {
                    margin-top: 10px;
                }
                &:hover {
                    background-color:$item-public-hover;
                    color: $primary-6;
                }
        
            }
        }



    }
    
    .content-layer {
        width: 100%;
        text-align: center;
        padding: 0px;
        padding-top: 100px;
        background-color: $primary-5;
        .content-header {
            font-weight: bolder;
        }
        &.offset {
            background-color: $primary-4;
        }
    }
    .infocard-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        .card-content {
            padding: 30px;
            max-width: 400px;
            min-width: 325px;
            text-align: center;
            &.max-width-800 {
                max-width: 800px;          
                .card-label {
                    padding-top: 40px;
                }      
            }
            .card-header {
                background-color: transparent;
                display: flex;
                justify-content: center;
    
                .card-icon {
                    border: 1px solid transparent;
                    width: 60px;
                }
            }
            .card-label {
                font-style: $font-main;
                font-weight: bolder;
            }
            .card-body {
                background-color: transparent;
            }
            .card-footer {
                background-color: transparent;
            }
        }
    }
    
    .content-layer-four {
        display: grid;
        padding: 200px;
        grid-template-columns: repeat(auto-fill, minmax(400px, 3fr));
        @media only screen and (max-width: 800px) {
            padding: 100px;
        }
        @media only screen and (max-width: 700px) {
            padding: 25px;
        }
        @media only screen and (max-width: 499px) {
            padding: 0px;
         }
        width: 100%;
        padding-top: 60px;
        padding-bottom: 30px;
        background-color: $primary-6;

        .content-header {
            font-weight: bolder;
            @media only screen and (max-width: 667px) {
                text-align: center;
            }
        }
        .card-container {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(500px, 3fr));
            @media only screen and (max-width: 499px) {
                grid-template-columns: repeat(auto-fill, minmax(400px, 3fr));
            }
        }
    
        .layer-img {
            padding-right: 40px;
            border-radius: 50%;
            padding: 40px;
            max-width: 500px;
            @media only screen and (max-width: 1217px) {
                display: none;
            }
        }
    
        .text-container {
            margin-right: 30px;
        }
    
        h1 {
            color: $primary-1;
            font-weight: bolder;
        }
    
        li {
            padding-top: 20px;
        }
    
        .learn-more-btn {
            margin-top: 20px;
            color: $primary-1;
            font-weight: 600;
            background-color: $primary-5;
            padding: 20px;
            // margin-right: 50px;
            border: none;
            // z-index: 999999 !important;;
            border-radius: 12px;
            transition: 0.3s ease-in-out;
            box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 16px;
            &:hover {
                background-color: $item-public-hover;
                color: $primary-6;
            }
        }

        
    }
    
    // .content-layer-one {
    //     background: linear-gradient(-45deg, #0b1725,  #314253,   #87a6c7,  #b5cde9);
    //     background-size: 400% 400%;
    //     animation: gradient 15s ease infinite;
    // }
    
    // @keyframes gradient {
    //     0% {
    //         background-position: 0% 50%;
    //     }
    //     50% {
    //         background-position: 100% 50%;
    //     }
    //     100% {
    //         background-position: 0% 50%;
    //     }
    // }
}
