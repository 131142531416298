.footer-component {
    $footer-min-height: 50px;

    min-height: $footer-min-height;
    width: calc(100vw);
    background-color: $primary-1;
    text-align: center;
    
    .footer-text {
        color: $primary-2;
        display: inline-block;
        font-size: 10px;
    }
}