

  .dropzone {
    text-align: center;
    padding: 20px;
    border: 3px dashed #eeeeee;
    background-color: #fafafa;
    color: #bdbdbd;
  
    margin-bottom: 20px;
  }
  
  .dropzone.accept {
    border-color: #107c10 !important;
  }
  
  .dropzone.reject {
    border-color: #d83b01 !important;
  }
  
  .dropzone-container img {
    transition-property: width, height;
    transition: 0.3s ease;
    object-fit: cover;
    width: 100%;
    height: 100%;
    margin-right: 5px;
    border-radius: 5px;
  }
  
  .dropzone-container button:focus {
    outline: none;
  }
  
  .dropzone-container .hoverArea {
    margin-top: -10px;
    padding-right: 5px;
    padding-bottom: 5px;
    display: inline-block;
  }
  
  .dropzone-container .hoverArea:hover > img {
    transition-property: width, height;
    transition: 0.3s ease;
    object-fit: cover;
    width: 105%;
    height: 105%;
    margin-right: 5px;
    border: dashed 1px black;
  }
  
  .dropzone-container .delete-img {
    transition-property: all;
    transition: 0.2s ease;
    opacity: 0;
    height: 30px;
    width: 30px;
    background-color: red;
    position: relative;
    top: -110px;
    right: -75px;
    border-radius: 100%;
    border: solid 2px black;
    padding-left: 6px;
    padding-top: 1px;
    font-size: 16px;
    color: white;
  }
  
  .dropzone-container .hoverArea:hover > .delete-img {
    transition-property: all;
    transition: 0.4s;
    opacity: 1;
    height: 30px;
    width: 30px;
    background-color: red;
    position: relative;
    top: -110px;
    right: -75px;
    border-radius: 100%;
    border: solid 2px black;
    padding-left: 6px;
    padding-top: 1px;
    font-size: 16px;
    color: white;
  }
  
  .dropzone-container .hoverArea:hover > .delete-img:hover {
    transition-property: all;
    transition: 0.4s ease;
    border-color: rgb(75, 75, 75);
  }
  
  .dropzone-container small {
    position: absolute;
    color: black;
    margin-top: -15px;
    margin-left: -25px;
  }
  
  .dropzone-container .img-preview-area {
    width: "100%";
  }
  
  .files-component-container img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 5px;
  }
  
  .files-component-container .small-container {
    display: inline-block;
    padding-left: 10px;
    padding-bottom: 10px;
  }
  
  .files-component-container button {
    margin-left: 10px;
  }
  
  .files-component-container li {
    list-style: circle;
    margin-left: 30px;
    padding-right: 10px;
  }
  
  .files-component-container .file-upload-previewdiv {
    background-color: #fafafa;
    border: dashed 3px #eeeeee;
    margin-left: 5;
    display: inline-block;
  }
  .files-component-container .previewsize,
  .dropzone-container .previewsize {
    width: 100px;
    height: 100px;
  }
