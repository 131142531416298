.landing-page-main-container {
    .area{
        background: $primary-3;  
        background: -webkit-linear-gradient(to left, $primary-3, #7177e7);  
        width: 100vw;
        height:100%;
        z-index: 300;
        //border-radius: 10%  0px 50% 0px;
    }
    
    .circles{
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: 200;
    }
    
    .circles li{
        opacity: 0;
        position: relative;
        display: block;
        list-style: none;
        width: 20px;
        height: 20px;
        background:$primary-5;
        // rgba(255, 255, 255, 0.2);
        animation: animate 25s linear infinite;
        bottom: -150px;
        filter: blur(10%);
        // opacity: 1;
        
    }
    
    .circles li:nth-child(1){
        left: 25%;
        width: 80px;
        height: 80px;
        animation-delay: 0s;
        filter: blur(90%)
    }
    
    
    .circles li:nth-child(2){
        left: 10%;
        width: 20px;
        height: 20px;
        animation-delay: 2s;
        animation-duration: 12s;
    }
    
    .circles li:nth-child(3){
        left: 70%;
        width: 20px;
        height: 20px;
        animation-delay: 4s;
    }
    
    .circles li:nth-child(4){
        left: 40%;
        width: 60px;
        height: 60px;
        animation-delay: 0s;
        animation-duration: 18s;
    }
    
    .circles li:nth-child(5){
        left: 65%;
        width: 20px;
        height: 20px;
        animation-delay: 0s;
    }
    
    .circles li:nth-child(6){
        left: 75%;
        width: 110px;
        height: 110px;
        animation-delay: 3s;
    }
    
    .circles li:nth-child(7){
        left: 35%;
        width: 150px;
        height: 150px;
        animation-delay: 7s;
    }
    
    .circles li:nth-child(8){
        left: 50%;
        width: 25px;
        height: 25px;
        animation-delay: 15s;
        animation-duration: 45s;
    }
    
    .circles li:nth-child(9){
        left: 20%;
        width: 15px;
        height: 15px;
        animation-delay: 2s;
        animation-duration: 35s;
    }
    
    .circles li:nth-child(10){
        left: 85%;
        width: 150px;
        height: 150px;
        animation-delay: 0s;
        animation-duration: 11s;
    }
    .circles li:nth-child(11){
        left: 15%;
        width: 150px;
        height: 150px;
        animation-delay: 0s;
        animation-duration: 11s;
    }
    .circles li:nth-child(12){
        left: 35%;
        width: 150px;
        height: 150px;
        animation-delay: 0s;
        animation-duration: 11s;
    }
    .circles li:nth-child(13){
        left: 85%;
        width: 150px;
        height: 150px;
        animation-delay: 0s;
        animation-duration: 11s;
    }
    .circles li:nth-child(14){
        left: 55.9%;
        width: 150px;
        height: 150px;
        animation-delay: 0s;
        animation-duration: 11s;
    }
    
    
    
    @keyframes animate {
    
        0%{
            transform: translateY(0) rotate(0deg);
            opacity: 0;
            border-radius: 30%;
            box-shadow: 0 0 4px 20px $primary-5;
        }

        50%{
            transform: translateY(-500px) rotate(360deg) scale(.7);
            opacity: .6;
            border-radius: 50%;
            box-shadow: 0 0 4px 50px $primary-5;
        }
    
        100%{
            transform: translateY(-1000px) rotate(720deg) scale(.6);
            opacity: 0;
            border-radius: 100%;
            box-shadow: 0 0 5px 80px $primary-5;
        }
    
    }
    
    /* .content-header-text {
        position: absolute;
    } */
}
